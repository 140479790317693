@import '../../../styles/variables.scss';
@import '../../../styles/functions.scss';
@import '../../../styles/mixins.scss';
@import './TableRow.scss';

.table {
  width: 100%;
  // min-height: calc(100vh - #{size(150px)});
  // min-height: calc(100 * var(--vh, 1vh) - #{size(150px)});

  table {
    width: 100%;
    height: 100%;
    // min-height: calc(100vh - #{size(150px)});
    // min-height: calc(100 * var(--vh, 1vh) - #{size(150px)});
    // height: 100vh;

    border-bottom: 2px solid $color-ghost-white-2;
    border-top: none;
    border-left: none;
    border-right: none;
    border-collapse: collapse;
  }

  tr {
    display: grid;
    grid-template-columns: min-content 50% 10% 10% 10% 10% 4.7%;
    min-height: size(51px);
    padding: 0 size(24px);
    border-bottom: 1px solid $color-ghost-white-2;
    align-items: center;
    transition: background-color $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: $color-alice-blue;
      }
    }

    &.table-checked {
      background-color: $color-alice-blue;
    }

    &.is-delete {
      td {
        opacity: 0.6;
      }

      .table-row__status svg {
        margin-left: size(5px);
        width: size(16px);
        height: size(16px);
      }
    }

    &.is-archived {
      .table-row__status svg {
        margin-left: size(3px);
      }
    }

    .dropdown-list__item .btn {
      width: 100%;
    }
  }

  th {
    color: $color-rock-blue;
    font-size: size(14px);
    font-weight: 500;
    padding-left: size(15px);

    &:first-of-type {
      padding-left: 0;
      min-width: size(46px);
    }
  }

  td {
    padding: size(5px) 0 size(5px) size(20px);
    display: flex;
    align-items: center;

    .btn--icon {
      width: size(38px);
      height: size(38px);
      padding: 0;
      background-color: $color-ghost-white;
    }

    .custom-toggle {
      width: size(46px);
      padding: 0;
    }

    &:first-of-type {
      padding-left: 0;
    }

    &.align-left {
      align-items: flex-start;
    }
  }

  &--header {
    min-height: size(50px);

    tr {
      text-align: left;
      min-height: size(48px);
      border-bottom: none;
      align-items: center;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          background-color: $color-ghost-white;
        }
      }
    }
  }

  &--project {
    th {
      padding-left: size(20px);
    }
    tr {
      //grid-template-columns: 19.3% 10% 11.9% 14.3% 13.3% 16.2% 10% 5%;
      grid-template-columns: 23.75% 23.75% 23.75% 23.75% 5%;
    }
  }

  &.scrolled {
    height: calc(100vh - #{size(200px)});

    overflow-y: auto;
    overflow-x: hidden;

    /*Кастомный скроллбар*/

    @include custom-scroll;

    &:after {
      content: "";
      height: size(107px);
      display: block;
    }
    &.table--pg:after {
      height: size(57px);
    }
  }
}
